import React, { useEffect } from 'react';

// Optional: Declare global variable for TypeScript
declare global {
  interface Window {
    Tawk_API: any;
  }
}

const TawkToChat: React.FC = () => {
  useEffect(() => {
    // Ensure the script is only added once
    if (document.getElementById('tawk-script')) return;

    const script = document.createElement('script');
    script.id = 'tawk-script';
    script.async = true;
    script.src = 'https://embed.tawk.to/6738afd02480f5b4f59f029c/1il3katsj';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    // Optional: set initial Tawk_API settings here if needed
    window.Tawk_API = window.Tawk_API || {};
  }, []);

  return null;
};

export default TawkToChat;

// <!--Start of Tawk.to Script-->
// <script type="text/javascript">
// var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/6738afd02480f5b4f59f029c/1il3katsj';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
// </script>
// <!--End of Tawk.to Script-->
